import React from 'react'
import { Link } from 'gatsby'

import Layout from 'layouts/Base'
import SEO from 'components/shared/SEO'
import ProductListing from 'components/ProductListing'

export default ({ data, location }) => {
  return (
    <>
      <SEO title="Plants"/>
      <ProductListing type="plant" title="All Plants"/>
    </>
  )
}
